import {
  Edit,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";

const year = [
  {
    id: "Y2023",
    label: "2023",
  },
  {
    id: "Y2024",
    label: "2024",
  },
];

const source = [
  {
    id: "AHA",
    label: "AHA",
  },
  {
    id: "AADA",
    label: "AADA",
  },
];

const EditPdf = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>

        <TextInput label="Title" source="title" multiline fullWidth required />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
          required
        />

<TextInput
          source="source"
          label="Source"
          value={"AADA"}
          defaultValue={"AADA"}
          required={true}
          hidden
        />
        <TextInput
          source="year"
          label="Year"
          value={"Y2024"}
          defaultValue={"Y2024"}
          required={true}
          hidden
        />
        <TextInput
          label="Priority"
          source="priority"
          fullWidth
          required={true}
          defaultValue={"1"}
        />
        <TextInput
          source="mediaType"
          label="Media Type"
          defaultValue={"Pdf"}
          hidden={true}
        />

        <ImageInput
          label="URL pdf"
          source="url"
          fullWidth
          format={(src: any) =>
            typeof src === "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditPdf;
