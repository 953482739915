import {
  ArrayField,
  ChipField,
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleList,
  SingleFieldList,
  TabbedShowLayout,
  TextField,
} from "react-admin";

export const UserShow = (props: any) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Account Infor">
          <FunctionField
            label="Name"
            render={(record: { firstName: any; lastName: any }) =>
              `${record.firstName} ${record.lastName}`
            }
            fullWidth
          />
          ;
          <TextField source="email" fullWidth />
          <TextField
            source="organization.name"
            label={"Company"}
            emptyText="Empty organization"
          />
          <TextField
            source="organization.country"
            label={"Country"}
            emptyText="Empty organization"
          />
          <ReferenceArrayField
            label="Categories"
            reference="admin/entry"
            source="entries"
            emptyText="Empty organization"
          >
            <SingleFieldList linkType={false}>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <DateField source="createdAt" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Company">
          <TextField source="organization.name" label="Name" />
          <TextField source="organization.country" label="Country" />
          <TextField source="organization.city" label="City" />
          <TextField source="organization.zipCode" label="ZipCode" />
          <TextField source="organization.address" label="Address" />
          <TextField source="organization.email" label="Company Email" />
          <TextField source="organization.website" label="Website" />
          <TextField source="organization.facebookUrl" label="Facebook" />
          <TextField source="organization.phone" label="Company Phone" />
          <DateField source="organization.createdAt" label="Created At" />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Project">
          <ArrayField source="projects">
            <Datagrid>
              <ReferenceField
                source="id"
                reference="admin/projects"
                emptyText="InActive Project"
              >
                <TextField source="id"></TextField>
              </ReferenceField>
              <ReferenceField
                label="Project Name"
                source="id"
                reference="admin/projects"
                emptyText="InActive Project"
              >
                <TextField source="name"></TextField>
              </ReferenceField>
              <ReferenceField
                label="Nominate"
                source="entryId"
                reference="admin/entry"
                emptyText="InActive category"
              >
                <TextField source="name"></TextField>
              </ReferenceField>

              <TextField source="location" />
              <TextField source="country" />
              <TextField source="owner" />
              <ArrayField source="pictures">
                <SimpleList
                  linkType={String}
                  primaryText={(record) => record}
                />
              </ArrayField>
              <TextField source="status" />
              <DateField source="deadline" />
              <DateField source="createdAt" />
              <ReferenceField
                label="Actions"
                source="id"
                reference="admin/projects"
                emptyText="InActive Project"
              >
                <EditButton />
              </ReferenceField>
            </Datagrid>
          </ArrayField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
