import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  List,
  NumberField,
  TextField,
  TopToolbar,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";

const NewsListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const NewsList = () => {
  return (
    <List actions={<NewsListActions />}>
      <Datagrid rowClick="show" optimized>
        <NumberField source="id" />
        <TextField source="title" />
        {/* <TextField source="shortContent" /> */}
        <DateField source="createdAt" />
        <ApcMediaField source="thumbnail" />
        <ApcMediaField source="wallpaper" />
        <TextField source="tags" />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
