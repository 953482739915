import { DataObject } from "@mui/icons-material";
import {
    TextField,
    DateField,
    NumberField,
    Show,
    ReferenceField,
    FunctionField,
    TabbedShowLayout,
    ArrayField,
    Datagrid,
    SimpleList,
    ExportButton,
    FilterButton,
    TopToolbar,
    ImageField,
    downloadCSV,
    SimpleShowLayout,
    Labeled,
    WrapperField,
} from "react-admin";


const ScoreShowAction = () => (
    <TopToolbar>
        <ExportButton />
    </TopToolbar>
);

export const ScoreProjectShow = (props: any) => {

    return (
        <Show actions={<ScoreShowAction />} {...props} title={
            <TextField source="projectName" />
        }>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Project">
                    <ReferenceField source="project.userId" reference="users" emptyText="InActive user" link="show" label="Creator">
                    </ReferenceField>
                    <ReferenceField label="Category" source="project.entryId" reference="admin/entry" emptyText="InActive category" link="show">
                    </ReferenceField>
                    <TextField source="project.name" />
                    <TextField source="project.location" />
                    <TextField source="project.country" />
                    <TextField source="project.idea" />
                    <TextField source="project.impact" />
                    <TextField source="project.differentiation" />
                    <TextField source="project.function" />
                    <TextField source="project.innovation" />
                    <TextField source="project.designer" />
                    <TextField source="project.manufacturer" />
                    <TextField source="project.differentiation" />
                    <TextField source="project.owner" />
                    {/* <ArrayField source="project.pictures" >
                        <SimpleList linkType={false}
                            primaryText={record => record}
                        />
                    </ArrayField> */}
                    <TextField source="project.status" />

                </TabbedShowLayout.Tab>


                <TabbedShowLayout.Tab label="Specific Point">
                    <ArrayField source="scores">
                        <Datagrid bulkActionButtons={false}>
                            <ReferenceField source="userId" reference="users" emptyText="InActive user" link="show" label="Judgement">
                            </ReferenceField>
                            <WrapperField label="Idea">
                                <TextField source="idea.comment" label="comment" />
                                <br></br>
                                <NumberField source="idea.score" label="point" />
                            </WrapperField>

                            <WrapperField label="function">
                                <TextField source="function.comment" label="comment" />
                                <br></br>
                                <NumberField source="function.score" label="point" />
                            </WrapperField>
                            <WrapperField label="innovation">
                                <TextField source="innovation.comment" label="comment" />
                                <br></br>
                                <NumberField source="innovation.score" label="point" />
                            </WrapperField>
                            <WrapperField label="differentiation">
                                <TextField source="differentiation.comment" label="comment" />
                                <br></br>
                                <NumberField source="differentiation.score" label="point" />
                            </WrapperField>
                            <WrapperField label="impact">
                                <TextField source="impact.comment" label="comment" />
                                <br></br>
                                <NumberField source="impact.score" label="point" />
                            </WrapperField>
                            <NumberField source="total"></NumberField>
                        </Datagrid>

                    </ArrayField>
                    <NumberField source="averageScore"></NumberField>

                </TabbedShowLayout.Tab>

            </TabbedShowLayout>

        </Show>
    );
};



