import {
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const EditItem = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <NumberInput label="EntryID" source="id" disabled />
        <TextInput source="name" fullWidth />
        {/* <SelectInput
          source="status"
          label="Trạng thái"
          {...projectStatusConfig}
        /> */}
        <ReferenceInput source="entryId" reference="admin/entry">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditItem;
