import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  List,
  TextField, ReferenceField
} from "react-admin";



export default () => {
  return (
    <List>
      <Datagrid optimized>
        <TextField source="id" />
        <ReferenceField source="userId" reference="users" emptyText="InActive user" />
        <ReferenceField source="entryId" reference="admin/entry" emptyText="InActive entry" label="Category">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="country" />
        <TextField source="designer" />
        <TextField source="manufacturer" />
        <TextField source="owner" />
        <TextField source="pictures" />
        <TextField source="score" />
      </Datagrid>
    </List>
  );
};
