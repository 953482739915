import {
  ChipField,
  CreateButton,
  Datagrid,
  DateField,
  EditButton,
  ExportButton,
  List,
  NumberField,
  ReferenceArrayField,
  ReferenceField,
  SingleFieldList,
  TextField,
  TopToolbar,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const ListItem = () => {
  return (
    <List>
      <Datagrid optimized isRowSelectable={(value) => false}>
        <ReferenceField label="User Email" source="userId" reference="users" emptyText="InActive user"
          link="show">
          <TextField source="email" />
        </ReferenceField>
        <ReferenceArrayField source="entries" reference="admin/entry">
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <NumberField
          source="totalFee"
          label="Amount"
          options={{ style: "currency", currency: "USD" }}
        />
        <TextField source="status" label="Payment Status" />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
