import { Create, NumberInput, ReferenceInput, SelectInput, SimpleForm } from "react-admin";

const CreatePayment = (props: any) => {
  return (
    <Create {...props} title="Create payment" redirect="/payment">
      <SimpleForm>
        <ReferenceInput label="User" source="payerID" reference="users" sort={{ field: 'createdAt', order: 'DESC' }} perPage={10000} >
          <SelectInput />
        </ReferenceInput>
        <NumberInput label="Value" source="value"></NumberInput>
      </SimpleForm>
    </Create>
  );
};

export default CreatePayment;
