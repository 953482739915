import { useState } from "react";
import {
  Edit,
  ImageInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";

const year = [
  {
    id: "Y2023",
    label: "2023",
  },
  {
    id: "Y2024",
    label: "2024",
  },
];

const source = [
  {
    id: "AHA",
    label: "AHA",
  },
  {
    id: "AADA",
    label: "AADA",
  },
];
const galleryType = [
  {
    id: "Video",
    label: "Video",
  },
  {
    id: "Image",
    label: "Image",
  },
  {
    id: "Pdf",
    label: "Pdf",
  },
];

const EditGallery = (props: any) => {
  const [mediaType, setMediaType] = useState<string | null>(null);

  const handleMediaTypeChange = (event: any) => {
    setMediaType(event.target.value);
  };
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>

        <TextInput
          label="Title"
          source="title"
          multiline
          fullWidth
          required={true}
        />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
          required={true}
        />

        {/* <SelectInput
          source="year"
          label="Year"
          choices={year}
          required={true}
          optionText="label"
          optionValue="id"
        /> */}

        {/* <SelectInput
          source="source"
          label="Source"
          defaultValue={{ id: "AHA", label: "AHA" }}
          choices={source}
          optionText="label"
          optionValue="id"
        /> */}
        <TextInput
          source="source"
          label="Source"
          value={"AADA"}
          defaultValue={"AADA"}
          required={true}
          hidden
        />
        <TextInput
          source="year"
          label="Year"
          value={"Y2024"}
          defaultValue={"Y2024"}
          required={true}
          hidden
        />
        <TextInput
          label="Priority"
          source="priority"
          fullWidth
          required={true}
          defaultValue={"1"}
        />
        {/* <TextInput
          source="mediaType"
          label="Media Type"
          value={"Image"}
          defaultValue={"Image"}
        /> */}
        <SelectInput
          source="mediaType"
          label="MediaType"
          defaultValue="Image"
          choices={galleryType}
          optionText="label"
          optionValue="id"
          required={true}
        />
        <ImageInput
          label="Images"
          source="images"
          fullWidth
          multiple
          accept="image/*"
          parse={(src) => {
            const value = Array.isArray(src) ? src : [src];
            return value.map((item) => {
              if (item instanceof File)
                return {
                  url: {
                    rawFile: item,
                    src: URL.createObjectURL(item),
                    title: item.name,
                  },
                };
              return item;
            });
          }}
          format={(src) => {
            if (!src) return src;
            const value = Array.isArray(src) ? src : [src];
            const result = value.map((item) => {
              if (typeof item?.url == "string") return item;

              const url = item?.url?.src || item?.url || item;
              return { url };
            });

            return result;
          }}
          isRequired={true}
        >
          <ApcMediaField source="url" />
        </ImageInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditGallery;
