import { find, forEach } from "lodash";
import { stringify } from "query-string";
import { DataProvider, fetchUtils } from "ra-core";
import {
  CreateParams,
  UpdateParams,
  withLifecycleCallbacks,
} from "react-admin";
import {
  // api2023Url,
  apiUrl,
  galleryUrl,
  winnerNightApiUrl,
} from "./model/AppConfig";
import * as CookieUtils from "./utils/CookieUtils";
import { uploadImageInParams } from "./utils/common";

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts?id=123&id=456&id=789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * export default App;
 */

const oldResource = [
  "news",
  "news/highlights",
  "users",
  "projects",
  "admin/entry",
  "admin/nominate-registration",
  "admin/organization",
  "payment",
  "score",
  "score/project",
  "admin/judgement-project",
  "winner-night/paypal/all",
];

function flattenObject(obj: any): any {
  if (typeof obj != "object") return obj;
  if (Array.isArray(obj)) {
    return obj.map(flattenObject).join(",");
  }
  const result: any = {};

  forEach(obj, (value, key) => {
    result[key] = flattenObject(value);
  });
  return result;
}

const resourceApi = {
  "admin/winners-2023": apiUrl,
  "media-center": galleryUrl,
  "winner-night": winnerNightApiUrl,
};

function getApiUrlForResource(resource: string): string {
  const api = find(resourceApi, (url, key) => resource.startsWith(key));
  return api || apiUrl || "";
}

const dataProvider = (httpClient = fetchUtils.fetchJson): DataProvider => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const { userId, projectId, entryId } = params.filter;

    const query = {
      ...flattenObject(params.filter),
      page: page - 1,
      perPage: perPage,
      sort: field,
      order: order,
      userId: userId,
      projectId: projectId,
      entryId: entryId,
    };
    const api = getApiUrlForResource(resource);

    const url = `${api}/${resource}?${stringify(query)}`;

    return httpClient(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    }).then(({ json }) => {
      if (!json["total"]) {
        return {
          data: [],
          total: 0,
          error: "No data available.",
        };
      }
      if (json.total === 0) {
        return {
          data: [],
          total: 0,
          error: "No data available.",
        };
      }
      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  getOne: (resource, params) =>
    httpClient(`${getApiUrlForResource(resource)}/${resource}/${params.id}`, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    }).then(({ json }) => ({
      data: oldResource.includes(resource) ? json.data : json,
    })),

  getMany: (resource, params) => {
    const query = {
      ids: flattenObject(params.ids),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    // const data = httpClient(url,
    //   {
    //     method: "GET",
    //     headers: headers,
    //   }).then(({ json }) => ({ data: json.data }))
    // console.log("data: " + data.catch)
    const token = CookieUtils.getToken();
    return httpClient(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then(({ json }) => ({ data: json.data }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      ...flattenObject(params.filter),
      [params.target]: params.id,
      sort: field,
      order: order,
      _start: (page - 1) * perPage,
      _end: page * perPage,
    };
    const url = `${getApiUrlForResource(resource)}/${resource}?${stringify(
      query
    )}`;
    const token = CookieUtils.getToken();

    return httpClient(url, {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    }).then(({ json }) => {
      if (!json["total"]) {
        throw new Error(
          "The total is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination."
        );
      }
      return {
        data: json.data,
        total: json.total,
      };
    });
  },

  update: (resource, params) =>
    httpClient(`${getApiUrlForResource(resource)}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    })
      .then(({ json }) => ({
        data: oldResource.includes(resource) ? json.data : json,
      }))
      .catch((error) => Promise.reject(error)),

  // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
  updateMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${getApiUrlForResource(resource)}/${resource}/${id}`, {
          method: "PUT",
          body: JSON.stringify(params.data),
          headers: new Headers({
            Authorization: `Bearer ${CookieUtils.getToken()}`,
          }),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),

  create: (resource, params) =>
    httpClient(`${getApiUrlForResource(resource)}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    })
      .then(({ json }) => ({
        data: json,
      }))
      .catch((error) => Promise.reject(error)),

  delete: (resource, params) =>
    httpClient(`${getApiUrlForResource(resource)}/${resource}/${params.id}`, {
      method: "DELETE",
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    })
      .then(({ json }) => ({ data: json.status }))
      .catch((error) => Promise.reject(error)),

  // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
  deleteMany: (resource, params) =>
    Promise.all(
      params.ids.map((id) =>
        httpClient(`${apiUrl}/${resource}/${id}`, {
          method: "DELETE",
          headers: new Headers({
            Authorization: `Bearer ${CookieUtils.getToken()}`,
          }),
        })
      )
    ).then((responses) => ({ data: responses.map(({ json }) => json.id) })),
});

const resources = [
  "news",
  "admin/winners-2023",
  "media-center/admin/gallery",
  "media-center/admin/images",
  "media-center/admin/video",
  "media-center/admin/pdf",
];

export default withLifecycleCallbacks(
  dataProvider(),
  resources.map((resource) => ({
    resource,
    beforeCreate: _beforeCreate,
    beforeUpdate: _beforeUpdate,
  }))
);

async function _beforeCreate(
  params: CreateParams<any>,
  dataProvider: DataProvider
): Promise<CreateParams<any>> {
  const result = await uploadImageInParams(params);

  return result;
}

function _beforeUpdate(
  params: UpdateParams<any>,
  dataProvider: DataProvider
): Promise<UpdateParams<any>> {
  return uploadImageInParams(params);
}
