const newsStatus = [
  {
    id: "Draft",
    label: "Nháp",
  },
  {
    id: "Submitted",
    label: "Đã đăng",
  },
];

export const newsStatusConfig = {
  optionText: "label",
  optionValue: "id",
  choices: newsStatus,
  defaultValue: "Draft",
};
