import {
  Datagrid,
  DateField,
  ExportButton,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
} from "react-admin";

const ProjectListAction = () => (
  <TopToolbar>
    <ExportButton />
  </TopToolbar>
);

const ProjectList = () => {
  return (
    <List actions={<ProjectListAction />}>
      <Datagrid rowClick="show" optimized>
        <NumberField label="EntryID" source="id" />
        <ReferenceField
          source="userId"
          reference="users"
          emptyText="InActive user"
          link="show"
        ></ReferenceField>
        <ReferenceField
          label="Category"
          source="entryId"
          reference="admin/entry"
          emptyText="InActive category"
          link="show"
        >
          <TextField source="name"></TextField>
        </ReferenceField>
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="country" />
        {/* <TextField source="idea" />
        <TextField source="impact" />
        <TextField source="differentiation" />
        <TextField source="function" /> */}
        {/* <TextField source="concept" />
        <TextField source="innovation" /> */}
        <TextField source="designer" />
        <TextField source="manufacturer" />
        {/* <TextField source="differentiation" /> */}
        {/* <TextField source="qualityOfService" />
        <TextField source="socialResponsibility" />
        <TextField source="forSpecialLifetimeAchievement" />
        <TextField source="organizationRepresentative" /> */}
        <TextField source="owner" />
        {/* <ArrayField source="pictures">
          <SimpleList linkType={false} primaryText={(record) => record} />
        </ArrayField> */}
        <TextField source="status" />
        {/* <DateField source="deadline" /> */}
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};

export default ProjectList;
