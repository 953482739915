import { Divider } from "@mui/material";
import {
  DateField,
  NumberField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const ItemDetail = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout sx={{ width: "50%" }} divider={<Divider flexItem />}>
        <NumberField source="id" fullWidth />
        <TextField source="title" fullWidth />
        <TextField source="shortContent" fullWidth />
        <TextField source="author" fullWidth />
        <RichTextField source="content" fullWidth />
        <DateField source="createdAt" />
        <TextField source="thumbnail" />
        <TextField source="wallpaper" />
        <TextField source="source" />
        <TextField source="tags" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};
