import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  List,
  TextField,
  TopToolbar,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";

const GalleryListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export default () => {
  return (
    <List actions={<GalleryListActions />}>
      <Datagrid rowClick="edit" optimized>
        <TextField source="id" />
        <ApcMediaField source="thumbnail" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="source" />
        <TextField source="priority" />
        <TextField source="mediaType" />
        <DateField source="createdAt" />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
