import { isEmpty, map } from "lodash";
import { uploadFile } from "./FileService";

function isFileItem(params: any): boolean {
  return params?.rawFile instanceof File && typeof params?.src == "string";
}

interface FileItem {
  rawFile: File;
  src: string;
}

export async function uploadImageInParams(params: any) {
  if (typeof params == "string") return params;

  const result: any = {};

  await Promise.all(
    map(params, async (value, key) => {
      if (isFileItem(value)) {
        const fileItem = value as FileItem;
        result[key] = await uploadFile(fileItem.rawFile, (_) => undefined);
        return;
      }
      if (Array.isArray(value)) {
        result[key] = await Promise.all(value.map(uploadImageInParams));
        return;
      }

      if (typeof value == "object") {
        if (isEmpty(value)) return;
        result[key] = await uploadImageInParams(value);
        return;
      }

      result[key] = value;
    })
  );

  return result;
}
