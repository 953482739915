import { Divider } from "@mui/material";
import { DateField, Show, SimpleShowLayout, TextField } from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";

export default (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <TextField source="id" />
        <ApcMediaField source="thumbnail" />
        <TextField source="title" />
        <TextField source="description" />
        <TextField source="source" />
        <TextField source="galleryType" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show>
  );
};
