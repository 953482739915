import {
  ChipField,
  Datagrid,
  DateField,
  ExportButton,
  FilterButton,
  FunctionField,
  List,
  ReferenceArrayField,
  SingleFieldList,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
const filters = [
  <TextInput type="mail" source="email" />,
  <TextInput source="ids" label="User Ids" />,
];

const UserListAction = () => (
  <TopToolbar>
    <ExportButton />
    <FilterButton />
  </TopToolbar>
);

export const UserList = () => {
  return (
    <List actions={<UserListAction />} filters={filters}>
      <Datagrid rowClick="show" optimized>
        <FunctionField
          label="Name"
          render={(record: { firstName: any; lastName: any }) =>
            `${record.firstName} ${record.lastName}`
          }
          fullWidth
        />
        ;
        <TextField source="email" fullWidth />
        <TextField
          source="organization.name"
          label={"Company"}
          emptyText="Empty organization"
        />
        <TextField
          source="organization.country"
          label={"Country"}
          emptyText="Empty organization"
        />
        <ReferenceArrayField
          label="Categories"
          reference="admin/entry"
          source="entries"
          emptyText="Empty organization"
        >
          <SingleFieldList linkType={false}>
            <ChipField source="name" />
          </SingleFieldList>
        </ReferenceArrayField>
        <DateField source="createdAt" />
      </Datagrid>
    </List>
  );
};
