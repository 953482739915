import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  FilterButton,
  FilterLiveSearch,
  List,
  NumberField,
  ReferenceInput,
  SelectField,
  TextField,
  TextInput,
  TopToolbar,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";

const FBLeadListActions = () => (
  <TopToolbar>
    <FilterLiveSearch source="email" />
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

export const FBLeadList = () => {
  return (
    <List actions={<FBLeadListActions />}>
      <Datagrid rowClick="edit" optimized>
        <NumberField source="id" />
        <TextField source="leadName" ></TextField>
        <TextField source="email" ></TextField>
        <TextField source="leadStatus" label="Leads Status" />
        <TextField source="country" ></TextField>
        <TextField source="source" ></TextField>
        <TextField source="notes" ></TextField>
        <TextField source="followUpAction" ></TextField>
        <DateField source="dateOfLastContact" ></DateField>
        <TextField source="outcomeOfLastContact" ></TextField>
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
