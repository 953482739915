import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    ExportButton,
    NumberField,
    ReferenceField,
    FilterButton,
    SelectInput,
    ReferenceInput,
} from "react-admin";

const scoreFilter = [
    <ReferenceInput label="Project" source="projectId" reference="projects" fullWidth={true} perPage={10000} >
        <SelectInput label="Project" source="name" />
    </ReferenceInput>
];

const ScoreListAction = () => (
    <TopToolbar>
        <ExportButton />
        <FilterButton filters={scoreFilter} />
    </TopToolbar>
);

export const ScoreByProjectList = () => {
    return (
        <List actions={<ScoreListAction />} filters={scoreFilter} >
            <Datagrid rowClick="show" optimized>
                <ReferenceField source="userId" reference="users" emptyText="InActive user" label="Judgement" />
                <ReferenceField source="projectId" reference="projects" emptyText="InActive project" label="Project">
                    <TextField source="name" />
                </ReferenceField>
                <ReferenceField source="entryId" reference="admin/entry" emptyText="InActive entry" label="Category">
                    <TextField source="name" />
                </ReferenceField>
                <TextField source="company" emptyText="InActive company" label="Company" />
                <NumberField source="averageScore" label="Average Score" />

            </Datagrid>
        </List >
    );
};

