import {
  DateInput,
  Edit,
  NumberInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
} from "react-admin";

const leadStatus = [
  {
    id: "WaitingToReply",
    label: "Waiting To Reply",
  },
  {
    id: "NeedToContact",
    label: "Need To Contact",
  },
  {
    id: "Converted",
    label: "Converted",
  },
  {
    id: "NeedToReply",
    label: "Need To Reply",
  },
];

const EditFBLead = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <TextInput source="leadName" ></TextInput>
        <TextInput source="email" validate={[required()]} disabled={true}></TextInput>
        <SelectInput source="leadStatus" label="Leads Status" choices={leadStatus} optionText="label" optionValue="id" />
        <TextInput source="country" ></TextInput>
        <TextInput source="source" ></TextInput>
        <TextInput source="notes" ></TextInput>
        <TextInput source="followUpAction" ></TextInput>
        <DateInput source="dateOfLastContact" ></DateInput>
        <TextInput source="outcomeOfLastContact" ></TextInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditFBLead;
