import * as CookieUtils from "../utils/CookieUtils";

export const apiUrl = "https://v2.ahawards.co/api";
// export const api2023Url = "https://api.aadawards.com/2023";
export const winnerNightApiUrl = "https://v2.ahawards.co";
export const fileUrl = "https://files.aadawards.com";
export const galleryUrl="https://ahawards.co";

export const loginURI = apiUrl + "/auth";
export const headers = new Headers({
  Authorization: `Bearer ${CookieUtils.getToken()}`,
});

