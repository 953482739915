import { Divider } from "@mui/material";
import {
  DateField,
  NumberField,
  ReferenceField,
  RichTextField,
  SelectField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";
import { newsStatusConfig } from "./common";
import styles from "./styles.module.scss";

const ShowItem = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <NumberField source="id" fullWidth />
        <ReferenceField source="projectId" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="author" reference="users">
          <TextField source="firstName" />
          <span> </span>
          <TextField source="lastName" />
        </ReferenceField>
        <RichTextField className={styles.richtext} source="content" fullWidth />
        <DateField source="createdAt" />
        <ApcMediaField source="thumbnail" />
        <ApcMediaField source="wallpaper" isOrigin />
        <SelectField source="status" label="Trạng thái" {...newsStatusConfig} />
      </SimpleShowLayout>
    </Show>
  );
};

export default ShowItem;
