import {
  ArrayInput,
  Create,
  DateInput,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";

const leadStatus = [
  {
    id: "WaitingToReply",
    label: "Waiting To Reply",
  },
  {
    id: "NeedToContact",
    label: "Need To Contact",
  },
  {
    id: "Converted",
    label: "Converted",
  },
  {
    id: "NeedToReply",
    label: "Need To Reply",
  },
];

const CreateFBLead = (props: any) => {
  return (
    <Create {...props} title="Create FBLead" redirect="/admin/fbLead">
      <SimpleForm>
        <TextInput source="leadName" ></TextInput>
        <TextInput source="email" validate={[required()]}></TextInput>
        <SelectInput source="leadStatus" label="Leads Status" choices={leadStatus} optionText="label" optionValue="id" />
        <TextInput source="country" ></TextInput>
        <TextInput source="source" ></TextInput>
        <TextInput source="notes" ></TextInput>
        <TextInput source="followUpAction" ></TextInput>
        <DateInput source="dateOfLastContact" ></DateInput>
        <TextInput source="outcomeOfLastContact" ></TextInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateFBLead;
