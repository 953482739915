import {
  ArrayInput,
  Create,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";
import { useState } from "react";
const year = [
  {
    id: "Y2023",
    label: "2023",
  },
  {
    id: "Y2024",
    label: "2024",
  },
];

const source = [
  {
    id: "AHA",
    label: "AHA",
  },
  {
    id: "AADA",
    label: "AADA",
  },
];
const galleryType = [
  {
    id: "Video",
    label: "Video",
  },
  {
    id: "Image",
    label: "Image",
  },
  {
    id: "Pdf",
    label: "Pdf",
  },

];
const CreateVideo = (props: any) => {
  const [mediaType, setMediaType] = useState<string | null>(null);

  const handleMediaTypeChange = (event: any) => {
    setMediaType(event.target.value);
  };
  return (
    <Create {...props} title="Create Gallery">
      <SimpleForm>
        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>

        <TextInput
          label="Title"
          source="title"
          multiline
          fullWidth
          required={true}

        />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
          required={true}

        />


        <TextInput
          source="source"
          label="Source"
          value={"AADA"}
          defaultValue={"AADA"}
          required={true}
          hidden
        />
        <TextInput
          source="year"
          label="Year"
          value={"Y2024"}
          defaultValue={"Y2024"}
          required={true}
          hidden
        />
        <TextInput
          label="Priority"
          source="priority"
          fullWidth
          required={true}
          defaultValue={"1"}
        />
        <TextInput
          source="mediaType"
          label="Media Type"
          defaultValue={"Video"}
          hidden={true}
        />


        <TextInput
          label="URL video"
          source="url"
          fullWidth
        />
      </SimpleForm>
    </Create>
  );
};

export default CreateVideo;
