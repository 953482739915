import { Divider, colors } from "@mui/material";
import {
  ArrayField,
  DateField,
  NumberField,
  ReferenceField,
  Show,
  SimpleList,
  SimpleShowLayout,
  TextField,
} from "react-admin";

const ProjectShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout sx={{ width: "50%" }} divider={<Divider flexItem />}>
        <NumberField label="EntryID" source="id" />
        <ReferenceField
          source="userId"
          reference="users"
          emptyText="InActive user"
          link="show"
          label="Judgement"
        ></ReferenceField>
        <ReferenceField
          label="Category"
          source="entryId"
          reference="admin/entry"
          emptyText="InActive category"
        >
          <TextField source="name"></TextField>
        </ReferenceField>
        <TextField source="name" />
        <TextField source="location" />
        <TextField source="country" />
        <TextField source="idea" />
        <TextField source="impact" />
        <TextField source="differentiation" />
        <TextField source="function" />
        <TextField source="concept" />
        <TextField source="innovation" />
        <TextField source="designer" />
        <TextField source="manufacturer" />
        <TextField source="differentiation" />
        <TextField source="qualityOfService" />
        <TextField source="socialResponsibility" />
        <TextField source="forSpecialLifetimeAchievement" />
        <TextField source="organizationRepresentative" />
        <TextField source="owner" />
        <ArrayField source="pictures">
          <p style={{ color: "red" }}>Ấn Ctrl+ Click hoặc Command + Click để mở trên tab mới</p>
          <SimpleList linkType={String} primaryText={(record) => record} />
        </ArrayField>{" "}
        <TextField source="status" />
        <DateField source="deadline" />
        <DateField source="createdAt" />
      </SimpleShowLayout>
    </Show >
  );
};

export default ProjectShow;
