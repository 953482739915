import {
  CreateButton,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  ExportButton,
  List,
  NumberField,
  ReferenceField,
  SelectArrayInput,
  SelectField,
  TextField,
  TopToolbar,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";
import { newsStatusConfig } from "./common";

const filters = [<SelectArrayInput source="status" {...newsStatusConfig} />];

const NewsListActions = () => (
  <TopToolbar>
    <CreateButton />
    <ExportButton />
  </TopToolbar>
);

const ListItem = () => {
  return (
    <List actions={<NewsListActions />} filters={filters}>
      <Datagrid rowClick="show">
        <NumberField source="id" />
        <ReferenceField source="projectId" reference="projects">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="author" reference="users">
          <TextField source="firstName" />
          <span> </span>
          <TextField source="lastName" />
        </ReferenceField>
        <DateField source="createdAt" />
        <ApcMediaField source="thumbnail" />
        <ApcMediaField source="wallpaper" />
        <TextField source="tags" />
        <SelectField source="status" label="Trạng thái" {...newsStatusConfig} />
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export default ListItem;
