import { RichTextInput } from "ra-input-rich-text";
import { ArrayInput, Create, ImageInput, ReferenceInput, SelectInput, SimpleForm, SimpleFormIterator, TextInput } from "react-admin";

const CreateHighlight = (props: any) => {
  return (
    <Create {...props} title="Create highlight" redirect="/news/highlights">
      <SimpleForm>
        <ReferenceInput label="id" source="id" reference="news">
          <SelectInput />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  );
};

export default CreateHighlight;
