import { Divider } from "@mui/material";
import {
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";

export const FBLeadShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <NumberField source="id" />
        <TextField source="leadName" ></TextField>
        <TextField source="email" ></TextField>
        <TextField source="leadStatus" label="Leads Status" />
        <TextField source="country" ></TextField>
        <TextField source="source" ></TextField>
        <TextField source="notes" ></TextField>
        <TextField source="followUpAction" ></TextField>
        <DateField source="dateOfLastContact" ></DateField>
        <TextField source="outcomeOfLastContact" ></TextField>
      </SimpleShowLayout>
    </Show>
  );
};
