import ImageIcon from "@mui/icons-material/Image";
import { ToggleButton, ToggleButtonProps } from "@mui/material";
import Image from "@tiptap/extension-image";
import StarterKit from "@tiptap/starter-kit";
import { AiFillYoutube } from "react-icons/ai";

import { useTranslate } from "ra-core";
import {
  AlignmentButtons,
  ClearButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  ListButtons,
  QuoteButtons,
  RichTextInput,
  RichTextInputProps,
  RichTextInputToolbar,
  useTiptapEditor,
} from "ra-input-rich-text";
import { Fragment, useCallback, useRef } from "react";
import { uploadFile } from "../../../utils/FileService";
import Iframe from "./Iframe";
import styles from "./styles.module.scss";
import CustomAlignmentButtons from "./CustomAlignmentButtons";
import TextAlign from "@tiptap/extension-text-align";
import { Editor, useEditor } from '@tiptap/react';

interface MyRichTextInputProps {
  editor: Editor | null;
  [key: string]: any; // other props
}
export const MyCustomRichTextInput: React.FC = () => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Iframe,
      Image,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
  });

  // Ensure the editor instance is initialized before rendering the CustomRichTextInput
  if (!editor) {
    return null;
  }

  return (
    <CustomRichTextInput
      label="content"
      source="content"
      fullWidth
      editor={editor}
    />
  );
}


// export function CustomRichTextInput(props: RichTextInputProps) {
const CustomRichTextInput: React.FC<MyRichTextInputProps> = (props) => {
  const { editor, ...rest } = props;
  return (
    <RichTextInput
      className={styles.container}
      fullWidth
      source=""
      toolbar={
        <RichTextInputToolbar>
          <LevelSelect />
          <FormatButtons />
          <AlignmentButtons></AlignmentButtons>
          {/* <CustomAlignmentButtons editor={editor} /> */}
          <ListButtons />
          <LinkButtons />
          <RichTextImageButtons />
          <RichTextYoutubeButtons />
          <QuoteButtons />
          <ClearButtons />
        </RichTextInputToolbar>
      }
      editorOptions={{
        extensions: [StarterKit, Iframe, Image, TextAlign.configure({ types: ['heading', 'paragraph'] })],
      }}
      {...rest}
    />
  );
}

const RichTextImageButtons = (props: Omit<ToggleButtonProps, "value">) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();

  const label = translate("ra.tiptap.image", { _: "Image" });
  const inputFile = useRef() as React.MutableRefObject<HTMLInputElement>;

  const addImage = useCallback(async () => {
    inputFile.current.click();
    inputFile.current.onchange = async (event) => {
      const input = event.target as HTMLInputElement;
      const files = input.files;
      if (!files?.length) return;
      const file = files.item(0);
      const src = await uploadFile(file, (progress) => undefined);
      const focus = editor.chain().focus();

      focus.setImage({ src }).run();
    };
  }, [editor, translate]);

  return editor ? (
    <Fragment>
      <input value="" type="file" accept="image/*" ref={inputFile} hidden />
      <ToggleButton
        aria-label={label}
        title={label}
        {...props}
        disabled={!editor?.isEditable}
        value="image"
        onClick={addImage}
      >
        <ImageIcon fontSize="inherit" />
      </ToggleButton>
    </Fragment>
  ) : null;
};

function getYoutubeIdFromUrl(url: string) {
  try {
    const uri = new URL(url);
    if (uri.hostname == "youtu.be") {
      return uri.pathname.replace(/^\//, "");
    }
    return uri.searchParams.get("v") || "";
  } catch (error) {
    return "";
  }
}

const RichTextYoutubeButtons = (props: Omit<ToggleButtonProps, "value">) => {
  const translate = useTranslate();
  const editor = useTiptapEditor();

  const label = translate("ra.tiptap.image", { _: "Image" });
  const inputFile = useRef() as React.MutableRefObject<HTMLInputElement>;

  const addImage = useCallback(async () => {
    const url = prompt("Enter YouTube URL") || "";
    const youtubeId = getYoutubeIdFromUrl(url);

    if (youtubeId) {
      editor.commands.setIframe({
        src: `https://www.youtube.com/embed/${youtubeId}`,
      });
    }
  }, [editor, translate]);

  return editor ? (
    <Fragment>
      <input value="" type="file" accept="image/*" ref={inputFile} hidden />
      <ToggleButton
        aria-label={label}
        title={label}
        {...props}
        disabled={!editor?.isEditable}
        value="image"
        onClick={addImage}
      >
        <AiFillYoutube color="red" />
      </ToggleButton>
    </Fragment>
  ) : null;
};
// export default CustomRichTextInput;