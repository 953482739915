import {
    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ExportButton,
    NumberField,
    ChipField,
    ReferenceManyField,
    ReferenceArrayField,
    SingleFieldList,
    ReferenceOneField,
    ReferenceField,
    FunctionField,
    downloadCSV,
    fetchRelatedRecords,
    CreateButton,
} from "react-admin";
import dataProvider from "../../dataProvider";

// https://marmelab.com/react-admin/List.html#exporter
// const exporter = (records: any) => {
//     fetchRelatedRecords(dataProvider)(records, 'userId', 'users').then(
//         (users: any[]) => {
//             console.log("users: " + users[1].firstName + ' ' + users[1].lastName);
            // const data = users.map((record: { userId: string; }) => ({
            //     ...record,
            //     Username: users[1].firstName + ' ' + users[1].lastName,
            // }));
            // const csv = convertToCSV({
            //     data,
            //     fields: ['id', 'userId', 'Username'],
            // });
            // downloadCSV(csv, 'payment');
//         });
// };

const PaymentListAction = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const PaymentList = () => {
    return (
        <List actions={<PaymentListAction />} >
            <Datagrid>
                {/* <TextField source="id" /> */}
                <ReferenceField source="userId" reference="users" emptyText="InActive user" link="show" label="Username">
                    <FunctionField label="Name" render={(record: { firstName: any; lastName: any; }) => `${record.firstName} ${record.lastName}`} />
                </ReferenceField>
                <TextField source="email" />
                <ReferenceField source="organizationId" reference="admin/organization" emptyText="Empty organization" link="show">
                </ReferenceField>
                <ReferenceField source="organizationId" label="Country" reference="admin/organization" emptyText="Empty organization" link="show">
                    <TextField source="country" />
                </ReferenceField>
                <ReferenceArrayField label="Categories" reference="admin/entry" source="entries" emptyText="Empty organization" >
                    <SingleFieldList linkType={false}>
                        <ChipField source="name" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="status" />
                <NumberField source="value" />
                <DateField source="createdAt" />
            </Datagrid>
        </List >
    );
};
// function convertToCSV(arg0: { data: { Username: any; userId: string; }[]; fields: string[]; }) {
//     throw new Error("Function not implemented.");
// }

