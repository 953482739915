import {
  AutocompleteInput,
  Edit,
  ImageInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";
// import CustomRichTextInput from "../common/CustomRichTextInput";
import { newsStatusConfig } from "./common";
import { useEditor, Editor, } from '@tiptap/react';
import StarterKit from "@tiptap/starter-kit";
import Iframe from "../common/CustomRichTextInput/Iframe";
import TextAlign from "@tiptap/extension-text-align";
import Image from "@tiptap/extension-image";
import { MyCustomRichTextInput } from "../common/CustomRichTextInput";

// const editor = useEditor({
//   extensions: [
//     StarterKit,
//     Iframe,
//     Image,
//     TextAlign.configure({ types: ['heading', 'paragraph'] }),
//   ],
// });
const EditItem = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <ReferenceInput
          label="designer"
          source="projectId"
          reference="projects"
          perPage={1000}
          sort={{
            field: "id",
            order: "ASC",
          }}
        >
          <AutocompleteInput
            fullWidth
            optionText={(record) => {
              const { id, name } = record;
              const idText = id + "";
              const result = `${id + " ".repeat(10 - idText.length)} - ${name}`;

              return (
                <div>
                  <span style={{ whiteSpace: "pre" }}>
                    {id}
                    {" ".repeat(6 - idText.length)}
                  </span>
                  <span> -- {name}</span>
                </div>
              );
            }}
            inputText={({ id, name }) => {
              return `${id}${" ".repeat(6 - (id + "").length)} -- ${name}`;
            }}
          />
        </ReferenceInput>
        {/* <CustomRichTextInput
          label="content"
          source="content"
          validate={required()}
          fullWidth
          editor={editor}
        /> */}
        <MyCustomRichTextInput/>
        <ImageInput
          label="Wallpaper"
          source="wallpaper"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" fullWidth />
        </ImageInput>
        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" fullWidth />
        </ImageInput>
        <SelectInput source="status" label="Trạng thái" {...newsStatusConfig} />
      </SimpleForm>
    </Edit>
  );
};

export default EditItem;
