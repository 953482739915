import {
  DateField,
  Edit,
  ReferenceArrayInput,
  ReferenceField,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin";

const EditItem = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <ReferenceField label="User Email" source="userId" reference="users">
          <TextInput source="email" fullWidth disabled />
        </ReferenceField>

        <ReferenceArrayInput source="entries" reference="admin/entry">
          <SelectArrayInput source="name" />
        </ReferenceArrayInput>

        <SelectInput
          source="status"
          label="Payment Status"
          defaultValue={{ id: "PENDING_PAYMENT" }}
          choices={["PENDING_PAYMENT", "PAID"].map((id) => ({ id, name: id }))}
        />
        <DateField source="createdAt" />
      </SimpleForm>
    </Edit>
  );
};

export default EditItem;
