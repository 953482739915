import {
  ArrayInput,
  Create,
  Edit,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";
const EditImages = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
      <ImageInput
          label="Url"
          source="url"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>

        <TextInput
          label="Title"
          source="title"
          multiline
          fullWidth
        />
        <NumberInput
          label="galleryId"
          source="galleryId"
          multiline
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default EditImages;
