import { AuthProvider, fetchUtils } from "react-admin";
import { apiUrl, loginURI } from "./model/AppConfig";
import * as CookieUtils from "./utils/CookieUtils";

/**
 * authProvider types
 */
const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const data = await fetchUtils.fetchJson(loginURI, {
      method: "POST",
      body: JSON.stringify({ username, password }),
    });
    const token = data.json.token;
    CookieUtils.setToken(token);
    // console.log("Token login: " + token);
    localStorage.setItem("username", username);
    return token;
  },

  checkAuth: () => {
    const token = CookieUtils.getToken();
    return token ? Promise.resolve() : Promise.reject();
  },

  checkError: (params: any) => {
    const { status } = params;
    if (status === 401 || status === 403) {
      CookieUtils.logout();
      return Promise.reject();
    }
    return Promise.resolve();
  },

  getPermissions: async () => {
    const res = await fetchUtils.fetchJson(apiUrl + "/users/permission", {
      method: "GET",
      headers: new Headers({
        Authorization: `Bearer ${CookieUtils.getToken()}`,
      }),
    });
    return res.json.data ? Promise.resolve(res.json.data) : Promise.reject();
  },
  logout: () => {
    CookieUtils.logout();
    return Promise.resolve();
  },
};

export default authProvider;
