import {
  ArrayInput,
  Edit,
  ImageInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  required,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";
import { MyCustomRichTextInput } from "../common/CustomRichTextInput";
// import { CustomRichTextInput } from "../common/CustomRichTextInput";

// function Title() {
//   const { record } = props || {};
//   return <span>{record.title}</span>;
// }

const EditItem = (props: any) => {
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <TextInput
          label="title"
          source="title"
          validate={required()}
          fullWidth
          resettable
        />
        <TextInput
          label="short content"
          source="shortContent"
          validate={required()}
          fullWidth
          multiline
          resettable
        />
        {/* <CustomRichTextInput
          label="content"
          source="content"
          validate={required()}
          fullWidth
        /> */}
        <MyCustomRichTextInput/>

        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" fullWidth />
        </ImageInput>
        <TextInput
          label="description"
          source="description"
          multiline
          fullWidth
        />
        <TextInput source="source" label="Nguồn" fullWidth multiline />
        <ImageInput
          label="Wallpaper"
          source="wallpaper"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" fullWidth />
        </ImageInput>
        <ArrayInput source="tags">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

export default EditItem;
