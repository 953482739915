import {
  ArrayInput,
  Create,
  Edit,
  ImageInput,
  NumberInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from "react-admin";
import ApcMediaField from "../../common/ApcMediaField";
import { useState } from "react";

const year = [
  {
    id: "Y2023",
    label: "2023",
  },
  {
    id: "Y2024",
    label: "2024",
  },
];

const source = [
  {
    id: "AHA",
    label: "AHA",
  },
  {
    id: "AADA",
    label: "AADA",
  },
];
const galleryType = [
  {
    id: "Video",
    label: "Video",
  },
  {
    id: "Image",
    label: "Image",
  },
  {
    id: "Pdf",
    label: "Pdf",
  },

];

const EditVideo = (props: any) => {
  const [mediaType, setMediaType] = useState<string | null>(null);

  const handleMediaTypeChange = (event: any) => {
    setMediaType(event.target.value);
  };
  return (
    <Edit {...props} title={<title />}>
      <SimpleForm>
        <ImageInput
          label="Thumbnail"
          source="thumbnail"
          fullWidth
          accept="image/*"
          format={(src) =>
            typeof src == "string" ? { src, type: props } : src
          }
        >
          <ApcMediaField source="src" />
        </ImageInput>

        <TextInput
          label="Title"
          source="title"
          multiline
          fullWidth
          
          required={true}


        />
        <TextInput
          label="Description"
          source="description"
          multiline
          fullWidth
          required={true}

        />

        <SelectInput source="year" label="Year"
          defaultValue={{ id: "Y2023", label: "2023" }}
          choices={year}
          optionText="label"
          optionValue="id" />

        <SelectInput source="source" label="Source"
          choices={source}
          optionText="label"
          optionValue="id" />
        <SelectInput
          source="mediaType"
          label="Media Type"
          choices={galleryType}
          optionText="label"
          optionValue="id"
          onChange={handleMediaTypeChange}
        />
        <TextInput
          label="Priority"
          source="priority"
          multiline
          fullWidth
          required={true}

        />
        <TextInput
          source="mediaType"
          label="Media Type"
          defaultValue={"Video"}
          disabled
          hidden={true}
        />

        <TextInput
          label="URL video"
          source="url"
          fullWidth
        />
      </SimpleForm>
    </Edit>
  );
};

export default EditVideo;
