import { Admin, Login, Resource } from "react-admin";
import authProvider from "./authProvider";
import { EntryList } from "./component/Entry/EntryList";
import CreateFBLead from "./component/FBLead/CreateFBLead";
import EditFBLead from "./component/FBLead/EditFbLead";
import { FBLeadList } from "./component/FBLead/FBLeadList";
import { FBLeadShow } from "./component/FBLead/FBLeadShow";
import CreateHighlight from "./component/HighlightNews/Create";
import { HighlightNewsList } from "./component/HighlightNews/HighlightNewsList";
import { HighlightShow } from "./component/HighlightNews/Show";
import AssignProject from "./component/JudgementProject/Create";
import { JudgementProjectList } from "./component/JudgementProject/JudmentProjectList";
import * as gallery from "./component/MediaCenter/Gallery";
import * as images from "./component/MediaCenter/Images";
import * as pdf from "./component/MediaCenter/Pdf";
import * as videos from "./component/MediaCenter/Video";
import CreateItem from "./component/News/Create";
import NewsEdit from "./component/News/NewsEdit";
import { NewsList } from "./component/News/NewsList";
import { NewsShow } from "./component/News/Show";
import EditItem from "./component/NomintateRegistration/Edit";
import { ListItem } from "./component/NomintateRegistration/List";
import { ItemDetail } from "./component/NomintateRegistration/Show";
import { OrganizationList } from "./component/Organization/OrganizationList";
import CreatePayment from "./component/Payment/Create";
import { PaymentList } from "./component/Payment/PaymentList";
import * as project from "./component/Project";
import { ScoreList } from "./component/Score/ScoreList";
import { ScoreShow } from "./component/Score/ScoreShow";
import { ScoreByProjectList } from "./component/ScoreProject/ScoreByProjectList";
import { ScoreProjectShow } from "./component/ScoreProject/ScoreProjectShow";
import { UserList } from "./component/User/UserList";
import { UserShow } from "./component/User/UserShow";
import * as winnerNews from "./component/WinnerNews";
import { WinnerNightList } from "./component/WinnerNight";
import * as voting from "./component/Voting"
import dataProvider from "./dataProvider";

const MyLoginPage = () => (
  <Login
    // A random image that changes everyday
    backgroundImage="https://scontent.fhan1-1.fna.fbcdn.net/v/t39.30808-6/328148937_542535747835797_5300199914408207961_n.png?_nc_cat=103&ccb=1-7&_nc_sid=e3f864&_nc_ohc=Ic31I5d7duQAX-ISzrD&_nc_oc=AQnEG5uzvcREy1FMr77cNPgg9UyVBvxkudOjnMoe1BmnB5o3ssAwHTvYz8bOiQ4dUjM&_nc_ht=scontent.fhan1-1.fna&oh=00_AfDxJTRy2nMhMmmXviA5dmPJn0dQNqdOVMn9iMCkDCdTxw&oe=649DF120"
  />
);

export default function App() {
  return (
    <Admin
      loginPage={MyLoginPage}
      authProvider={authProvider}
      dataProvider={dataProvider}
      requireAuth
    >
      <Resource {...voting} />

      <Resource
        name="news"
        show={NewsShow}
        list={NewsList}
        edit={NewsEdit}
        create={CreateItem}
        recordRepresentation={(record) => `${record.title}`}
      />
      <Resource
        name="news/highlights"
        list={HighlightNewsList}
        show={HighlightShow}
        create={CreateHighlight}
      />
      <Resource {...gallery} />
      <Resource {...images} />
      <Resource {...videos} />
      <Resource {...pdf} />
      <Resource
        name="admin/fbLead"
        list={FBLeadList}
        edit={EditFBLead}
        create={CreateFBLead}
        show={FBLeadShow}
      />

      <Resource
        name="users"
        options={{ label: "User/Registration" }}
        show={UserShow}
        list={UserList}
        recordRepresentation={(record) =>
          `${record.firstName} ${record.lastName}`
        }
      />
      <Resource {...project} />
      <Resource
        name="admin/nominate-registration"
        options={{ label: "Payment" }}
        list={ListItem}
        show={ItemDetail}
        edit={EditItem}
      />
      <Resource name="payment" list={PaymentList} create={CreatePayment} options={{ label: "Paypal Paid" }}
      />
      {/* <Resource
        name="admin/organization"
        // show={UserShow}
        list={OrganizationList}
        recordRepresentation={(record) => `${record.name}`}
      /> */}
      {/* comment to hide until need to show */}
      {/* <Resource name="score" list={ScoreList} show={ScoreShow} />
     
      <Resource
        name="admin/judgement-project"
        // show={UserShow}
        list={JudgementProjectList}
        create={AssignProject}
      />
      <Resource name="winner-night/paypal/all" list={WinnerNightList} /> */}
       <Resource
        name="score/project"
        list={ScoreByProjectList}
        show={ScoreProjectShow}
      />
      <Resource {...winnerNews} />

    </Admin>
  );
}
