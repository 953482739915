import get from "lodash/get";
import { useEffect, useState } from "react";
import { ImageFieldProps, useRecordContext } from "react-admin";
import styles from "./styles.module.scss";

export interface ApcMediaFieldProps extends ImageFieldProps {
  isOrigin?: boolean;
  onlyImage?: boolean;
}

const supportUrls = [
  "https://files-uat.aadawards.com",
  "https://files.aadawards.com",
];

function isSupported(src: any): boolean {
  if (typeof src != "string") return false;
  return supportUrls.find((domain) => src.startsWith(domain)) != null;
}

function getOptimizeImageUrl(src: string): string {
  if (!isSupported(src)) return src;
  return `${src}?size=w200`;
}

export default (props: ApcMediaFieldProps) => {
  const { isOrigin = false, source, title, fullWidth, onlyImage } = props;
  const record = useRecordContext(props);
  const sourceValue = get(record, source ?? "");

  if (!sourceValue) return null;

  const titleValue = get(record, title ?? "") || title;
  const optimizedSrc = isOrigin
    ? sourceValue
    : getOptimizeImageUrl(sourceValue);

  const [isVideo, setIsVideo] = useState(false);
  useEffect(() => {
    setIsVideo(false);
  }, [sourceValue]);

  if (isVideo) {
    return (
      <video
        title={titleValue}
        src={optimizedSrc}
        controls
        className={[fullWidth ? styles.fullWidth : styles.image].join(" ")}
      />
    );
  }
  return (
    <img
      title={titleValue}
      alt={titleValue}
      src={optimizedSrc}
      className={[fullWidth ? styles.fullWidth : styles.image].join(" ")}
      onError={(event) => (onlyImage ? null : setIsVideo(true))}
    />
  );
};
