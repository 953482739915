import { Divider } from "@mui/material";
import {
  DateField,
  NumberField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
} from "react-admin";
import ApcMediaField from "../common/ApcMediaField";
import styles from "./styles.module.scss";

export const NewsShow = (props: any) => {
  return (
    <Show {...props}>
      <SimpleShowLayout divider={<Divider flexItem />}>
        <NumberField source="id" fullWidth />
        <TextField source="title" fullWidth />
        <TextField source="shortContent" fullWidth />
        <TextField source="author" fullWidth />
        <RichTextField className={styles.richtext} source="content" fullWidth />
        <DateField source="createdAt" />
        <ApcMediaField source="thumbnail" fullWidth />
        <ApcMediaField source="wallpaper" isOrigin fullWidth />
        <TextField source="source" />
        <TextField source="tags" />
        <TextField source="description" />
      </SimpleShowLayout>
    </Show>
  );
};
