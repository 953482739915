import axios from "axios";
import { fileUrl } from "../model/AppConfig";

type ValueChanged<T> = (value: T) => void;
export async function uploadFile(
  file: File | null,
  onProgressChanged: ValueChanged<number>
): Promise<string> {
  if (!file) throw new Error("Item is empty");

  const formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const url = `${fileUrl}/upload`;
  const resp = await axios.post<{ url: string }>(url, formData, {
    ...config,
    onUploadProgress: (event) => onProgressChanged((event.progress ?? 0) * 100),
  });
  return resp.data.url;
}
